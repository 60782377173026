import type React from "react";
import { useEffect, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { TronLinkAdapterName } from "@tronweb3/tronwallet-adapter-tronlink";
import { toast } from "sonner";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectValue,
	SelectTrigger,
} from "@/components/ui/select";
import { ConnectButton } from "@/components/connectButton";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import { Link } from "@tanstack/react-router";

// Constants
const TRONLINK_IMAGE = "/tronlink.jpg";
const ADDRESS_CHARS = 8;

const shortenAddress = (address: string, chars = ADDRESS_CHARS): string => {
	if (!address) return "";
	return `${address.slice(0, chars)}...${address.slice(-chars)}`;
};

interface WalletButtonProps {
	isMobile?: boolean;
	onDisconnect: () => void;
	address: string;
}

interface MobileMenuProps {
	connected: boolean;
	onDisconnect: () => void;
	address: string;
}

const WalletButton: React.FC<WalletButtonProps> = ({
	isMobile = false,
	onDisconnect,
	address,
}) => (
	<Button
		variant="secondary"
		onClick={onDisconnect}
		className={`flex items-center justify-start gap-2 ${isMobile ? "w-full" : ""}`}
	>
		<img src={TRONLINK_IMAGE} alt="TronLink" className="h-5 rounded-sm" />
		<span className="truncate">{shortenAddress(address)}</span>
	</Button>
);

const MobileMenu: React.FC<MobileMenuProps> = ({
	connected,
	onDisconnect,
	address,
}) => (
	<div className="flex flex-col gap-4">
		{connected ? (
			<WalletButton isMobile onDisconnect={onDisconnect} address={address} />
		) : (
			<ConnectButton />
		)}
		<Select defaultValue="english">
			<SelectTrigger className="w-full">
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="english">English</SelectItem>
				<SelectItem value="chinese">Chinese</SelectItem>
			</SelectContent>
		</Select>
		{/* <ThemeToggle /> */}
	</div>
);

// Main component
export const Header: React.FC = () => {
	const { select, connected, disconnect, wallet } = useWallet();

	// biome-ignore lint/correctness/useExhaustiveDependencies: Needs to be re-run when wallet changes
	useEffect(() => {
		select(TronLinkAdapterName);
	}, [select, wallet]);

	const handleDisconnect = useCallback(async () => {
		try {
			await disconnect();
		} catch (error) {
			toast.error("Error disconnecting wallet");
			console.error("Error disconnecting wallet:", error);
		}
	}, [disconnect]);

	const address = wallet?.adapter.address || "";

	return (
		<>
			<div className="w-full bg-secondary py-1 text-center font-semibold">
				Grand opening 9. 12. 2024
			</div>
			<header className="fixed top-0 z-50 flex h-[70px] w-full items-center justify-center border-b bg-card/80 py-2 text-card-foreground text-md shadow-sm backdrop-blur-md">
				<div className="mx-auto flex w-full max-w-[1250px] items-center justify-between px-2 md:grid md:grid-cols-layout md:gap-4">
					<Link
						href="/"
						className="flex items-center justify-center gap-2 overflow-hidden font-black"
					>
						<img src="/logo-row.webp" alt="logo" className="h-9 md:h-11" />
					</Link>
					<div className="hidden items-center justify-end gap-2 md:flex md:flex-row">
						<div className="flex flex-row items-center gap-2">
							{/* Add flex properties here */}
							{connected ? (
								<WalletButton onDisconnect={handleDisconnect} address={address} />
							) : (
								<ConnectButton />
							)}
							<Select defaultValue="english">
								<SelectTrigger>
									<SelectValue />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="english">English</SelectItem>
									{/* <SelectItem value="chinese">Chinese</SelectItem> */}
								</SelectContent>
							</Select>
						</div>
						{/* <ThemeToggle /> */}
					</div>
					<div className="md:hidden">
						<Sheet>
							<SheetTrigger asChild>
								<Button variant="outline" size="icon">
									<Menu className="h-[1.2rem] w-[1.2rem]" />
								</Button>
							</SheetTrigger>
							<SheetContent>
								<MobileMenu
									connected={connected}
									onDisconnect={handleDisconnect}
									address={address}
								/>
							</SheetContent>
						</Sheet>
					</div>
				</div>
			</header>
		</>
	);
};
